var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-cal",
        {
          staticStyle: { height: "500px" },
          attrs: {
            xsmall: "",
            "time-from": 5 * 60,
            "time-to": 22 * 60,
            "time-cell-height": 60,
            "default-view": "day",
            events: _vm.events,
            "time-step": 30,
            "on-event-click": _vm.onEventClick
          }
        },
        [_vm._v("\n    :disable-views=\"['years', 'year', 'month']\" >\n  ")]
      ),
      _c(
        "a-modal",
        {
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c("h2", [_vm._v(_vm._s(_vm.selectedEvent.title))]),
          _c("a-divider"),
          _c("strong", [
            _vm._v(_vm._s(_vm.nFormat(_vm.selectedEvent.startDate)))
          ]),
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.selectedEvent.content) }
          }),
          _c("strong", [_vm._v("Event details:")]),
          _c("ul", [
            _c("li", [
              _vm._v("Location: " + _vm._s(_vm.selectedEvent.location))
            ]),
            _c("li", [
              _vm._v("Event starts at: " + _vm._s(_vm.selectedEvent.startTime))
            ]),
            _c("li", [
              _vm._v("Event ends at: " + _vm._s(_vm.selectedEvent.endTime))
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }