var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("n-page", [
    _vm.user.user_type === "user"
      ? _c(
          "div",
          [
            _c(
              "a-card",
              {
                staticClass: "gx-card-full",
                attrs: { title: "Your Schedule" }
              },
              [_c("member-calendar")],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "a-row",
              { attrs: { type: "flex" } },
              [
                _c(
                  "a-col",
                  { attrs: { xs: 24, sm: 24, md: 6 } },
                  [
                    _c("n-box", { attrs: { to: "/teams-list" } }, [
                      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("\n            Teams\n          ")
                      ]),
                      _c(
                        "div",
                        { attrs: { slot: "description" }, slot: "description" },
                        [
                          _vm._v(
                            "\n            Schedule new training session, add and remove team members and\n            manage anything else related to that team.\n          "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { xs: 24, sm: 24, md: 6 } },
                  [
                    _c("n-box", { attrs: { to: "/members" } }, [
                      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("\n            Global Members\n          ")
                      ]),
                      _c(
                        "div",
                        { attrs: { slot: "description" }, slot: "description" },
                        [
                          _vm._v(
                            "\n            Manage your global clubs members, grow your club by inviting new\n            members or just manage your existing members.\n          "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { xs: 24, sm: 24, md: 6 } },
                  [
                    _c("n-box", { attrs: { to: "/club/billing" } }, [
                      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("\n            Billing\n          ")
                      ]),
                      _c(
                        "div",
                        { attrs: { slot: "description" }, slot: "description" },
                        [
                          _vm._v(
                            "\n            View a breakdown of your clubs billing history. Track pending\n            payments and view individual transaction invoices.\n          "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }