var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "box-list", attrs: { tag: "div", to: _vm.to } },
    [
      _vm.icon ? _c("div", { staticClass: "box-list--icon" }) : _vm._e(),
      _c(
        "div",
        { staticClass: "box-list--body" },
        [
          _vm._t("default"),
          _c("h4", [_vm._t("title")], 2),
          _vm._t("description")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }